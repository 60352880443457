@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
  margin: 0;
  box-sizing: border-box;
  font-family: "Manrope", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  color: inherit;
}

body {
  min-height: 100vh;
}

table {
  border-collapse: collapse;
  width: 100%;
}
thead th {
  background-color: #254769;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  padding: 15px;
}

tbody td {
  padding: 15px;
}

tbody td:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
tbody td:last-child {
  padding: 15px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

td,
th {
  text-align: left;
  padding: 8px;
}

tr {
  transition: all 0.3s;
}

tr:hover {
  background-color: #e8e8e8;
  box-shadow: 2px 4px 30px rgba(0, 0, 0, 0.1);
}

.font-inter {
  font-family: "Manrope", sans-serif !important;
}

.site-layout {
  min-height: 93vh;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #254769;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ml-large {
  margin-left: 10.8rem;
}

.ml-xl {
  margin-left: 11.8rem;
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #fff !important;
}

form input,
textarea {
  border: none;
  background: hsl(0 0% 93%);
  border-radius: 0.25rem;
  padding: 0.75rem 1rem;
  outline: 0;
  font-size: 14px;
}

h2.ant-typography,
.ant-typography h2 {
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 30px;
  line-height: 1.35;
}

.btn {
  color: white;
  outline: 0;
  width: 100%;
  border: 0;
  padding: 15px;
  cursor: pointer;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0px;
}
/* .ant-modal, .ant-modal-content {
 margin: 0;
 top: 0;
} */
.ant-modal-content {
  border-radius: 10px;
}
.ant-modal-body {
  padding: 0;
}
.ant-modal-close {
  margin-top: 10px;
  margin-right: 20px;
}
.searchBtn {
  color: gray;
  padding: 3px 30px 4px 5px;
  height: 10px;
  width: 120px;
  font-family: "Manrope", sans-serif;
  font-size: 15px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-repeat: no-repeat;
  background-position: top 5px right -2px;
  display: flex;
  position: relative;
}
.arrowDown {
  width: 10px;
  position: absolute;
  top: 10px;
  right: 5px;
}
.arrowUp {
  width: 10px;
  position: absolute;
  top: 35px;
  right: 15px;
}
.dropdown {
  background-color: whitesmoke;
  position: absolute;
  top: 32%;
  left: 23%;
  padding: 15px;
  font-size: 13px;
  width: 150px;
  color: black;
  cursor: pointer;
}
.dropdown > li {
  margin-top: 10px;
  padding: 3px 0;
}
.box {
  position: absolute;
  top: 10%;
  left: 10%;
  transform: translate(-50%, -50%);
}

.box select {
  background-color: whitesmoke;
  color: gray;
  padding: 5px;
  width: 100px;
  border: none;
  font-size: 20px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  -webkit-appearance: button;
  appearance: button;
  outline: none;
}

.box::before {
  content: "\f13a";
  font-family: FontAwesome;
  position: absolute;
  top: 0;
  right: 0;
  width: 20%;
  height: 100%;
  text-align: center;
  font-size: 28px;
  line-height: 45px;
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.1);
  pointer-events: none;
}

.box:hover::before {
  color: rgba(255, 255, 255, 0.6);
  background-color: rgba(255, 255, 255, 0.2);
}

.box select option {
  padding: 30px;
}

/* react-toastify theme config */
:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #254769;
  --toastify-color-success: #21b241;
  --toastify-color-warning: #ff932f;
  --toastify-color-error: #f45b5b;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  /* Used only for colored theme */
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  /* Used when no type is provided // toast("hello") */
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );

  /* Used when no type is provided */
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);

  /* used to control the opacity of the progress trail */
  --toastify-color-progress-bgo: 0.2;
}
